export var NodeIconType;
(function (NodeIconType) {
    NodeIconType[NodeIconType["EXCAVATOR"] = 0] = "EXCAVATOR";
    NodeIconType[NodeIconType["TRACTOR"] = 1] = "TRACTOR";
    NodeIconType[NodeIconType["POWER"] = 2] = "POWER";
    NodeIconType[NodeIconType["BATTERY"] = 3] = "BATTERY";
    NodeIconType[NodeIconType["ESS"] = 4] = "ESS";
    NodeIconType[NodeIconType["TRUCKWITHESS"] = 5] = "TRUCKWITHESS";
    NodeIconType[NodeIconType["WARNING"] = 6] = "WARNING";
    NodeIconType[NodeIconType["CAT950"] = 7] = "CAT950";
    NodeIconType[NodeIconType["IDLEMECCHARGER"] = 8] = "IDLEMECCHARGER";
    NodeIconType[NodeIconType["CHARGEMECCHARGER"] = 9] = "CHARGEMECCHARGER";
    NodeIconType[NodeIconType["WHEELLOADER"] = 10] = "WHEELLOADER";
})(NodeIconType || (NodeIconType = {}));
export var NodeIconColor;
(function (NodeIconColor) {
    NodeIconColor["CHARGING"] = "network-green";
    NodeIconColor["DISCHARGING"] = "network-orange";
    NodeIconColor["IDLE"] = "network-blue";
    NodeIconColor["INACTIVE"] = "network-grey";
    NodeIconColor["TRANSPORTING"] = "network-pink";
})(NodeIconColor || (NodeIconColor = {}));
