export const baseUrls = {
    caasV1ApiDev: {
        alwaysPassThrough: true,
        url: "https://api.cat.com/catDigital/caas/v1"
    },
    cdnCookieApis: {
        alwaysPassThrough: true,
        url: "https://cdn.cookielaw.org"
    },
    clickTrackingApi: {
        alwaysPassThrough: true,
        url: "https://stats.g.doubleclick.net"
    },
    eflowV1ApiDev: {
        alwaysPassThrough: true,
        url: "https://api.cat.com/catDigital/eflow/v1"
    },
    entitlementsV1Api: {
        alwaysPassThrough: true,
        url: "https://api.cat.com/catDigital"
    },
    fontsGoogleApis: {
        alwaysPassThrough: true,
        url: "https://fonts.googleapis.com"
    },
    geolocationAPi: {
        alwaysPassThrough: true,
        url: "https://geolocation.onetrust.com"
    },
    googleAnalyticsApi: {
        alwaysPassThrough: true,
        url: "https://www.google-analytics.com"
    },
    mapsGoogleApis: {
        alwaysPassThrough: true,
        url: "https://maps.googleapis.com"
    },
    privacyPortalApi: {
        alwaysPassThrough: true,
        url: "https://privacyportal.onetrust.com"
    },
    self: {
        alwaysPassThrough: true,
        url: "/"
    },
    signInCat: {
        alwaysPassThrough: true,
        url: "https://signin.cat.com"
    },
    whatFix: {
        alwaysPassThrough: true,
        url: "https://*.whatfix.com"
    }
};
export const baseUrlsList = Object.keys(baseUrls).map((baseUrl) => baseUrls[baseUrl]);
export const endpoints = {
    caasProjectDetail: {
        baseUrl: baseUrls.caasV1ApiDev,
        getUrl: (id, queryParams) => `/projects/${id}/assets/search${queryParams && "?" + queryParams}`,
        path: "/projects/:projectId/assets/search"
    },
    caasProjectEnergyHistogram: {
        baseUrl: baseUrls.caasV1ApiDev,
        getUrl: (id) => `/projects/${id}/energy/histogram`,
        path: "/projects/:projectId/energy/histogram"
    },
    caasProjectEnergyMetrics: {
        baseUrl: baseUrls.caasV1ApiDev,
        getUrl: (id) => `/projects/${id}/energy/metrics`,
        path: "/projects/:projectId/energy/metrics"
    },
    caasProjectNetworkConnections: {
        baseUrl: baseUrls.caasV1ApiDev,
        getUrl: (id) => `/projects/${id}/network/connections`,
        path: "/projects/:projectId/network/connections"
    },
    caasProjectsSearch: {
        baseUrl: baseUrls.caasV1ApiDev,
        getUrl: (queryParams) => `/projects/search${queryParams && "?" + queryParams}`,
        path: "/projects/search"
    },
    eflowAssetsChargeOptions: {
        baseUrl: baseUrls.eflowV1ApiDev,
        getUrl: () => `/assets/chargeOptions`,
        path: "/assets/chargeOptions"
    }
};
export const endpointsList = Object.keys(endpoints).map((endpoint) => endpoints[endpoint]);
