import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CatLoadingIndicator } from "blocks-react/bedrock/components/LoadingIndicator/LoadingIndicator";
import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
export var CaasRoute;
(function (CaasRoute) {
    CaasRoute["BatteryDetails"] = "/battery-details/:assetId";
    CaasRoute["CreateNewProject"] = "/new-project";
    CaasRoute["Home"] = "/";
    CaasRoute["Logout"] = "/logout";
    CaasRoute["ProjectDetails"] = "/project-details/:id";
    CaasRoute["RestrictedAccess"] = "/restricted-access";
})(CaasRoute || (CaasRoute = {}));
const Projects = lazy(() => import("../pages/Projects/Projects"));
const ProjectDetails = lazy(() => import("../pages/Projects/ProjectDetails"));
const CreateNewProject = lazy(() => import("../pages/Projects/CreateNewProject"));
const AssetBatteryDetails = lazy(() => import("../pages/Projects/AssetBatteryDetails"));
const RestrictedError = lazy(() => import("../pages/Error/RestrictedError"));
const Logout = lazy(() => import("../pages/Logout/Logout"));
const Router = () => (_jsx(Suspense, { fallback: _jsx("div", { className: "flex justify-center items-center h-full min-h-[100vh]", children: _jsx(CatLoadingIndicator, {}) }), children: _jsxs(Routes, { children: [_jsx(Route, { element: _jsx(Projects, {}), path: CaasRoute.Home }), _jsx(Route, { element: _jsx(ProjectDetails, {}), path: CaasRoute.ProjectDetails }), _jsx(Route, { element: _jsx(CreateNewProject, {}), path: CaasRoute.CreateNewProject }), _jsx(Route, { element: _jsx(AssetBatteryDetails, {}), path: CaasRoute.BatteryDetails }), _jsx(Route, { element: _jsx(RestrictedError, {}), path: CaasRoute.RestrictedAccess }), _jsx(Route, { element: _jsx(Logout, {}), path: CaasRoute.Logout }), _jsx(Route, { element: _jsx(Navigate, { to: CaasRoute.Home }), path: "*" })] }) }));
export default Router;
